import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import DatePicker from 'components/DatePicker'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import Segment from 'components/Segment'
import Textarea from 'components/Textarea'
import UploadSingleImage from 'components/UploadSingleImage'
import { canManageProperties } from 'lib/can'
import { getCompanyResultEditRoute, getPropertyRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import Select from 'components/Select'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const CompanyResultCreatePage = ({ me }) => {
  const history = useHistory()
  const queryResult = useQuery('getProperties', {
    fetchPolicy: 'network-only',
  })
  const properties = queryResult.properties || []
  const [companyResultCreate] = useMutation('companyResultCreate')
  const {
    formik: createCompanyResultFormik,
    buttonProps: buttonCreateCompanyResultProps,
    alertProps: alertCreateCompanyResultProps,
  } = useForm({
    initialValues: {
      propertySerialNumber: '',
      name: '',
      url: '',
      serialNumber: '',
      profitInvestors: '',
      roiInvestorsProject: '',
      roiInvestorsYear: '',
      duration: '',
      durationType: 'months',
      purchaseSum: '',
      expensesSum: '',
      sellingPrice: '',
      sellingCurrency: 'rub',
      contentMd: '',
      photo: '',
      finishType: 'finished',
      date: '',
      hidden: '0',
    },
    validationSchema: yup.object({
      // propertySerialNumber: yup.string(),
      name: yup.string(),
      url: yup.string(),
      serialNumber: yup.number(),
      profitInvestors: yup.number(),
      roiInvestorsProject: yup.number(),
      roiInvestorsYear: yup.number(),
      duration: yup.number(),
      durationType: yup.string(),
      purchaseSum: yup.number(),
      expensesSum: yup.number(),
      sellingPrice: yup.number(),
      sellingCurrency: yup.string(),
      contentMd: yup.string(),
      photo: yup.string(),
      finishType: yup.string(),
      date: yup.string().required(),
      hidden: yup.string(),
    }),
    onSubmit: (allValues) => {
      const { propertySerialNumber, ...values } = allValues
      return companyResultCreate({
        variables: {
          input: {
            ...values,
            serialNumber: +values.serialNumber || 0,
            profitInvestors: +values.profitInvestors || 0,
            roiInvestorsProject: +values.roiInvestorsProject || 0,
            roiInvestorsYear: +values.roiInvestorsYear || 0,
            duration: +values.duration || 0,
            purchaseSum: +values.purchaseSum || 0,
            expensesSum: +values.expensesSum || 0,
            sellingPrice: +values.sellingPrice || 0,
            hidden: !!Number(values.hidden),

            // date: moment(values.date, 'D.M.YYYY').toDate(),
          },
        },
      }).then(({ companyResult }) => {
        history.push(getCompanyResultEditRoute(companyResult.serialNumber))
      })
    },
    successMessage: false,
  })
  useEffect(() => {
    const property = properties.find(
      (property) => property.serialNumber === createCompanyResultFormik.values.propertySerialNumber
    )
    if (!property) {
      return
    }
    const duration = (() => {
      if (!property.startsAt || !property.endsAt) {
        return property.maxPeriod
      } else {
        const diffMs = new Date(property.endsAt).getTime() - new Date(property.startsAt).getTime()
        const diffMonths = diffMs / (1000 * 60 * 60 * 24 * 30)
        console.log(diffMonths)
        return Math.round(diffMonths) || 1
      }
    })()
    createCompanyResultFormik.setFieldValue('name', property.name)
    createCompanyResultFormik.setFieldValue(
      'url',
      'https://estateinvest.online' + getPropertyRoute(property.serialNumber)
    )
    createCompanyResultFormik.setFieldValue('serialNumber', property.serialNumber)
    // createCompanyResultFormik.setFieldValue('profitInvestors', Math.ceil(property.profitSum || 0 * 100) / 100)
    createCompanyResultFormik.setFieldValue('profitInvestors', '')
    createCompanyResultFormik.setFieldValue(
      'roiInvestorsProject',
      property.roi?.split('-')[0].replace(',', '.').replace('%', '')
    )
    // createCompanyResultFormik.setFieldValue(
    //   'roiInvestorsYear',
    //   property.roi?.split('-')[0].replace(',', '.').replace('%', '')
    // )
    createCompanyResultFormik.setFieldValue('roiInvestorsYear', '')
    createCompanyResultFormik.setFieldValue('duration', duration)
    createCompanyResultFormik.setFieldValue('durationType', 'months')
    // createCompanyResultFormik.setFieldValue('purchaseSum', property.purchasePrice)
    createCompanyResultFormik.setFieldValue('purchaseSum', '')
    // createCompanyResultFormik.setFieldValue('expensesSum', property.expensesSum)
    createCompanyResultFormik.setFieldValue('expensesSum', property.additionalExpenses)
    console.log(property.additionalExpenses)
    createCompanyResultFormik.setFieldValue('sellingPrice', property.sellingPrice)
    createCompanyResultFormik.setFieldValue('sellingCurrency', property.sellingCurrency)
    createCompanyResultFormik.setFieldValue('date', new Date(property.endsAt || new Date()).toISOString())
    createCompanyResultFormik.setFieldValue('hidden', '0')
    createCompanyResultFormik.setFieldValue('finishType', property.endsAt ? 'finished' : 'partial')
  }, [createCompanyResultFormik.values.propertySerialNumber])
  return (
    <Segment title="Создание пользователя">
      <Form formik={createCompanyResultFormik}>
        <FormItems>
          <Select
            formik={createCompanyResultFormik}
            name={'propertySerialNumber'}
            label={'На основании объекта'}
            options={[
              { value: '', label: 'Произвольного' },
              ...properties.map((property) => ({
                value: property.serialNumber,
                label: property.name,
              })),
            ]}
          />
          <Input name="name" label="Название" formik={createCompanyResultFormik} />
          <Input name="url" label="Ссылка" formik={createCompanyResultFormik} />
          <Input name="serialNumber" label="Серийный номер" formik={createCompanyResultFormik} />
          <Input name="profitInvestors" label="Прибыль инвесторов" formik={createCompanyResultFormik} />
          <Input name="roiInvestorsProject" label="Доходность инвесторов проект" formik={createCompanyResultFormik} />
          <Input name="roiInvestorsYear" label="Доходность инвесторов год" formik={createCompanyResultFormik} />
          <Input name="duration" label="Длительность" formik={createCompanyResultFormik} />
          <RadioButtons label="Тип длительности" formik={createCompanyResultFormik} name="durationType">
            <RadioButton formik={createCompanyResultFormik} label="Месяцы" name="durationType" value={'months'} />
            <RadioButton formik={createCompanyResultFormik} label="Дни" name="durationType" value={'days'} />
          </RadioButtons>
          <Input name="purchaseSum" label="Сумма покупки" formik={createCompanyResultFormik} />
          <Input name="expensesSum" label="Сумма расходов" formik={createCompanyResultFormik} />
          <Input name="sellingPrice" label="Цена продажи" formik={createCompanyResultFormik} />
          <RadioButtons label="Валюта" formik={createCompanyResultFormik} name="sellingCurrency">
            <RadioButton formik={createCompanyResultFormik} label="Рубль" name="sellingCurrency" value={'rub'} />
            <RadioButton formik={createCompanyResultFormik} label="Евро" name="sellingCurrency" value={'euro'} />
            <RadioButton formik={createCompanyResultFormik} label="Доллар" name="sellingCurrency" value={'usd'} />
            <RadioButton formik={createCompanyResultFormik} label="USDT" name="sellingCurrency" value={'usdt'} />
          </RadioButtons>
          <Textarea name="contentMd" label="Контент" formik={createCompanyResultFormik} />
          <UploadSingleImage label="Главное фото" name="photo" formik={createCompanyResultFormik} />
          <RadioButtons label="Тип готовности" formik={createCompanyResultFormik} name="finishType">
            <RadioButton formik={createCompanyResultFormik} label={'Завершен'} name="finishType" value={'finished'} />
            <RadioButton
              formik={createCompanyResultFormik}
              label={'Частично завершен'}
              name="finishType"
              value={'partial'}
            />
          </RadioButtons>
          <DatePicker withTime={false} name="date" label="Дата" formik={createCompanyResultFormik} />
          <RadioButtons label="Скрыт" formik={createCompanyResultFormik} name="hidden">
            <RadioButton formik={createCompanyResultFormik} label={'Нет'} name="hidden" value={'0'} />
            <RadioButton formik={createCompanyResultFormik} label={'Да'} name="hidden" value={'1'} />
          </RadioButtons>
          <Alert {...alertCreateCompanyResultProps} />
          <Buttons>
            <Button {...buttonCreateCompanyResultProps}>Создать результат</Button>
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageProperties(me)}>
    {({ me }) => <CompanyResultCreatePage me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
