import PageWrapper from 'components/PageWrapper'
import { getCompanyResultsRoute } from 'lib/routes'
import { useEffect } from 'react'
// import { useHistory } from 'react-router-dom/cjs/react-router-dom'

export const ResultsPage = ({ me, history }) => {
  // const history = useHistory()
  useEffect(() => {
    // window.location.replace('https://telegra.ph/Nashi-rezultaty-04-17-2')
    history.replace(getCompanyResultsRoute())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <PageWrapper queryResult={{ loading: true }} />
}
